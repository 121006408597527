import Vue from 'vue'
import { createPinia, PiniaVuePlugin } from 'pinia'
import '@/css/tailwind.css'
import './plugins/quasar'
import './plugins/firebase'
import i18n from './boot/i18n'
import './boot/moment'
import './boot/notify-defaults'
import App from './App.vue'
import router from './router'
import store from './store'
import './sw-removal'

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.config.productionTip = false

new Vue({
  router,
  store,
  pinia,
  i18n,
  render: h => h(App)
}).$mount('#app')
