import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyAVuYxca9LbL9sfFgUTazPa5qDCpVXqZEw',
  authDomain: 'talana-notificaciones.firebaseapp.com',
  databaseURL: 'https://talana-notificaciones.firebaseio.com',
  projectId: 'talana-notificaciones',
  storageBucket: 'talana-notificaciones.appspot.com',
  messagingSenderId: '387197034009',
  appId: '1:387197034009:web:33576efbb2a68892081488',
  measurementId: 'G-MYG8WMD6VS'
}

export const firebaseApp = initializeApp(firebaseConfig)
export const analytics = getAnalytics(firebaseApp)

export const logFirebaseEvent = (name = '', params = {}) => {
  const profile = JSON.parse(localStorage.profile)
  const userData = {
    user_id: profile?.myId,
    company_id: profile?.empresa,
    os: 'MiTalana',
    ...params
  }
  logEvent(analytics, name, userData)
}
